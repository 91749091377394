var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$style.card},[_c('h3',{class:[_vm.$style.titleOrder, 'text-h3']},[_vm._v(" Заполните данные ")]),_c('v-form',{ref:"form",class:_vm.$style.controls},[_c('div',{class:_vm.$style.itemsRow},[_c('Select',{class:_vm.$style.item,attrs:{"value":_vm.new_order.project,"items":_vm.projects,"item_value":"id","item-text":"name","label":"Проект","blue_icon":"","clearable":"","disabled":""},on:{"input":function($event){return _vm.upd_new_order('project_id', $event)}}}),_c('Select',{class:_vm.$style.item,attrs:{"value":_vm.new_order.organization,"items":_vm.organizations,"item_value":"id","item-text":"name_organization","label":"Организация","require":"","blue_icon":"","rules":[
          function (v) { return _vm.is_require(v) || _vm.require_message; }
        ],"disabled":_vm.is_disable_organization || _vm.loading},on:{"input":function($event){return _vm.upd_new_order('organization', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('Select',{class:_vm.$style.item,attrs:{"value":_vm.payment_method_value,"items":_vm.payment_types,"label":"Форма оплаты","require":"","rules":[
          function (v) { return _vm.is_require(v) || _vm.require_message; }
        ],"blue_icon":"","disabled":!_vm.has_organization},on:{"input":function($event){return _vm.upd_new_order('payment_type', $event)}}}),_c('Select',{class:_vm.$style.item,attrs:{"value":_vm.new_order.delivery_type,"items":_vm.delivery_methods,"label":"Способы доставки","blue_icon":"","disabled":!_vm.has_organization},on:{"input":function($event){return _vm.upd_new_order('delivery_type', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('Select',{class:_vm.$style.item,attrs:{"value":_vm.new_order.warehouse,"items":_vm.warehouses,"item_value":"id","item-text":"address","label":"Склад АТОЛ","require":"","menu-props":{ openOnHover: true },"rules":[
          function (v) { return _vm.is_require(v) || _vm.require_message; }
        ],"blue_icon":""},on:{"input":function($event){return _vm.upd_new_order('warehouse', $event)}}}),(_vm.is_delivery_selected)?_c('Select',{class:_vm.$style.item,attrs:{"value":_vm.new_order.delivery_address,"items":_vm.delivery_addresses,"label":"Адрес доставки","item_value":"id","item-text":"address","require":"","rules":[
          function (v) { return _vm.is_require(v) || _vm.require_message; }
        ],"blue_icon":""},on:{"input":function($event){return _vm.upd_new_order('delivery_address', $event)}}}):_vm._e()],1),_c('div',{class:_vm.$style.itemsRow},[(_vm.is_delivery_selected)?_c('Select',{class:_vm.$style.item,attrs:{"value":_vm.new_order.transport_company_id,"items":_vm.transport_companies,"label":"Транспортные компании","item_value":"id","item-text":"name_transport_company","require":"","rules":[
          function (v) { return _vm.is_require(v) || _vm.require_message; }
        ],"blue_icon":""},on:{"input":function($event){return _vm.upd_new_order('transport_company_id', $event)}}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }